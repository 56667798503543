import React from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';

// import { Usecases } from 'styles/usecases';
// import { ComingSoonLogo } from '../styles/integrations';
import LoginWithGoogle from '../components/LoginWithGoogle';
import TrustedBy from '../components/TrustedBy';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/usecases.scss';
import 'styles/integrations.scss';

import NewFaq from '../components/NewFaq/NewFaq';
import FeatureContainer from '../components/FeatureContainer';

const faqData = [
  {
    id: nanoid(),
    q: 'Are all integrations available across all subscription plans?',
    a: (
      <>
        You’ll get unlimited access to integrations like Slack, Trello, asana
        and Jira in the Free and Standard plan. In the Pro, Team and Enterprise
        plan, you’ll get ClickUp & Zapier as additional integrations.
      </>
    ),
  },
  {
    id: nanoid(),
    q: 'Where can I find all my integrations in one place?',
    a: 'Integrations are project-specific, meaning, each project will have its own individual integration settings. To access the same, please open the project and on the sub-header, click on ‘Integrations’ to access all integrations in one place.',
  },
  {
    id: nanoid(),
    q: 'Will anyone else be able to see my integrations?',
    a: 'No, only you can see the integrations enabled by you on your project.',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));

const Integrations = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Integrations | ruttl"
          description="Simplify the reviewing of web projects with your team by integrating ruttl with your favourite work tools like Trello, Slack, Asana and many more."
          url="https://ruttl.com/integrations/"
        />
        <main className="usecases-styled-main">
          <section className="features-hero custom-padding">
            <div className="container">
              <h1>Integrations</h1>
              <p>
                Integrate ruttl with your preferred work tools and make
                reviewing easier for you and your team members.
              </p>
              {/* <a href="https://web.ruttl.com/signup" className="button" >
              Get Started for Free
            </a> */}

              <div
                className="reveal-1 button-container"
                style={{ marginBottom: 30 }}>
                <LoginWithGoogle
                  styleProp={{
                    padding: '5px 35px',
                  }}
                />

                <div>
                  <a
                    className="button button-white"
                    href="https://web.ruttl.com"
                    target="_blank"
                    rel="noreferrer noopener">
                    Try now for free
                    <svg width="20" height="20" viewBox="0 0 512 512">
                      <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M268 112l144 144-144 144M392 256H100"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <TrustedBy />
          </section>

          <div className="section features-main" id="features">
            <div className="container">
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/zapier-integration.png"
                          alt="Zapier ruttl integration creative"
                          title="Zapier ruttl integration creative"
                          style={{ height: 400 }}
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Integrate with 3000+ apps using Zapier
                      </h2>
                      <p>
                        Integrate with Zapier and get ruttl&apos;s project
                        updates on any platform. Create Zaps and set the flow of
                        information you require from ruttl to your preferred
                        app.
                      </p>
                      <a
                        className="button"
                        href="https://youtu.be/vrff3vYs93I"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know more
                      </a>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/asana.png"
                          alt="Asana ruttl integration creative"
                          title="Asana ruttl integration creative"
                          style={{ height: 400 }}
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Manage your project activities with asana
                      </h2>
                      <p>
                        Add comments/tickets from your ruttl project to your
                        preferred asana project. Assign these comments/tickets
                        as tasks to your team members, give it a due date and
                        set the level of priority. These comments/tickets will
                        also contain information about the device & browser,
                        using which the comment was sent.
                      </p>
                      <a
                        className="button"
                        href="https://youtu.be/__k-hFkcdjo"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know more
                      </a>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <video
                          src="/assets/img/view-comments-trello.mp4"
                          title="Integrate with Trello to manage and assign ruttl comments in Kanban style on your preferred Trello board."
                          aria-hidden
                          autoPlay
                          muted
                          loop
                          playsInline></video>
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        View comments and edits on your Trello board
                      </h2>
                      <p>
                        See your comments/tickets and edits as cards on your
                        preferred Trello board and add them to your preferred
                        Trello list. You can then add a description to this
                        card, assign it to team members, add labels, change due
                        dates, etc.
                      </p>
                      <a
                        className="button"
                        href="https://youtu.be/uahueQJV-lE?t=26"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know more
                      </a>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/project-activity-slack.png"
                          alt="ruttl slack integration ui shot"
                          title="ruttl slack integration ui shot"
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Get notified about project activities on your Slack
                      </h2>
                      <p>
                        Integrate ruttl with your Slack account and receive all
                        project updates like comments/tickets and edits and
                        project changes - directly on Slack.
                      </p>
                      <a
                        className="button"
                        href="https://youtu.be/uahueQJV-lE"
                        target="_blank"
                        rel="noopener noreferrer">
                        Know more
                      </a>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/jira.png"
                          alt="Jira ruttl integration creative"
                          title="Jira ruttl integration creative"
                          style={{ height: 400 }}
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Manage your projects better with Jira
                      </h2>
                      <p>
                        Integrate with Jira to send your comment/tickets
                        automatically to your preferred Jira project. To turn
                        this integration on, visit your project, click on
                        Integrations and switch the toggle on for Jira. Login to
                        your Jira account, then follow the quick process to
                        successfully integrate with Jira.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          loading="lazy"
                          src="/assets/img/clickup.png"
                          alt="ClickUp ruttl integration creative"
                          title="ClickUp ruttl integration creative"
                          style={{ height: 400 }}
                        />
                      </div>
                    </div>
                    <div className="feature-txt">
                      <h2 className="feature-head">
                        Fast forward your projects with ClickUp
                      </h2>
                      <p>
                        Integrate with ClickUp to send your comments/tickets
                        automatically to your preferred ClickUp space. To turn
                        this integration on, visit your project, click on
                        Integrations and switch the toggle on for ClickUp. Login
                        to your ClickUp account, then follow the quick process
                        and allow permissions to successfully integrate with
                        ClickUp. You can then set priority, add tags, assign it
                        to another member or add a due date.
                      </p>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>

          <section className="coming-soon-styled">
            <div className="container">
              <h2>Coming soon</h2>
              <div className="clients-box-area">
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/plutio.png"
                        alt="plutio logo"
                        title="plutio logo"
                      />
                    </div>
                  </div>
                </div>

                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/ms-teams.png"
                        alt="MS teams logo"
                        title="MS teams logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/teamwork.png"
                        alt="teamwork logo"
                        title="teamwork logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/figma.png"
                        alt="Figma logo"
                        title="Figma logo"
                      />
                    </div>
                  </div>
                </div>

                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/zendesk.png"
                        alt="Zendesk logo"
                        title="Zendesk logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/monday.png"
                        alt="Monday.com logo"
                        title="Monday.com logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/zoom.png"
                        alt="Zoom logo"
                        title="Zoom logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/nifty-pm.png"
                        alt="Nifty PM logo"
                        title="Nifty PM logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/ora.png"
                        alt="Ora logo"
                        title="Ora logo"
                      />
                    </div>
                  </div>
                </div>
                <div className="client-col">
                  <div className="client-single">
                    <div className="client-img">
                      <img
                        src="/assets/img/logo/zoho-cliq.png"
                        alt="Zoho Cliq logo"
                        title="Zoho Cliq logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <GetStartedCenter toggleSignup={toggleSignup} />
          <TestimonialsSlider />
          <NewFaq data={faqData} />
        </main>
      </>
    )}
  </Layout>
);

Integrations.propTypes = {
  location: PropTypes.object,
};

export default Integrations;
